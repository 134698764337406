<template>
   <v-footer cols="12" lg="12" class="google-fonts-footer" style="background-color: #393c8b ;"> <!--#293668-->
      <v-card flat tile class="contain transparent">
        <v-card-text>
          <v-row align="center" justify="space-between">
          <v-col class="nameL" cols="12"  sm="4" lg="3">
            <strong class="text-h4">SERMA GROUP</strong>
          </v-col>

        


          <v-col class="nameL" cols="12" sm="4" lg="3">
            <!--<v-btn icon v-for="icon in icons" :key="icon" class="mx-1" :style="{color:'white'}">-->
              <v-btn icon v-for="item in icons" :key="item.icon" class="mx-1" :style="{color:'white'}"  @click="goToUrl(item.url)">
              <v-icon size="24px">{{ item.icon }}</v-icon>
            </v-btn>
          </v-col>

          </v-row>
        </v-card-text>

       
      <v-divider></v-divider>

<v-card-text class=" transparent white--text">
  <v-row >
    <v-col  cols="12" sm="6" class=" text-center">
      {{ new Date().getFullYear() }} — <strong>SERMAGROUP. All right reserved.</strong>
    </v-col>
    <v-col cols="12" sm="6" class="text-center">
      Support: proyectos@sermagroup.com.mx
    </v-col>
  </v-row>
</v-card-text>

      </v-card>




       <!--<v-row>
         
        <v-col class="contain-title" cols="12" md="4" lg="4">
          <h2 class="span1">SERMA GROUP</h2>
         
        </v-col>

        <v-col class="back"><span class="span2"></span></v-col>

        <v-col >
          <v-btn class="btns" color="transparent">
                <v-icon color="white" left>mdi-instagram</v-icon>
                  <h4>Instagram</h4>
          </v-btn> 
        </v-col>

        <v-col >
          <v-btn class="btns" color="transparent">
                <v-icon color="white" left>mdi-facebook</v-icon>
                  <h4>Facebook</h4>
                </v-btn>
        </v-col>


        <v-col class="contain-data" cols="12" lg="5">
          <v-row class="demo">
            <h2>Av. punta norte 128 c. Queretaro,Qro</h2>
            <v-icon color="white"  size="x-large" left>mdi-map-marker</v-icon>
          </v-row>
          
          <v-row class="demo">
            <h2>proyectos@sermagroup.com.mx</h2>
            <v-icon color="white" size="x-large" left>mdi-email</v-icon>
          </v-row>
        </v-col>
         
          
       </v-row>-->

<!--<v-col class="contain-title" cols="12" md="4" lg="4">
           <h2 class="span1">SERMA GROUP</h2>
   
           <v-row>
              <v-col >
                <v-btn class="btns" color="transparent">
                <v-icon color="white" left>mdi-instagram</v-icon>
                  <h4>Instagram</h4>
                </v-btn>
              </v-col>

               <v-col >
                <v-btn class="btns" color="transparent">
                <v-icon color="white" left>mdi-facebook</v-icon>
                  <h4>Facebook</h4>
                </v-btn>
              </v-col>
           </v-row>

         </v-col>
         <v-col class="contain-data" cols="12" lg="8">
          <v-row class="demo">
            <h2>Av. punta norte 128 c. Queretaro,Qro</h2>
            <v-icon color="white"  size="x-large" left>mdi-map-marker</v-icon>
          </v-row>
          
          <v-row class="demo">
            <h2>proyectos@sermagroup.com.mx</h2>
            <v-icon color="white" size="x-large" left>mdi-email</v-icon>
          </v-row>
          </v-col>-->
          
     
   </v-footer>
   
 </template>


<script>
export default{
   name: 'FooterV',
   data: () => ({

    icons: [
    { icon: 'mdi-facebook', url: 'https://www.facebook.com/sermagroupmx?mibextid=ZbWKwL' },
    { icon: 'mdi-linkedin', url: 'https://www.linkedin.com/company/servicio-y-mantenimiento/' },
    { icon: 'mdi-map-marker', url: 'https://goo.gl/maps/' }
  ]
   
  }),

  methods: {
    goToUrl(url) {
      window.open(url, '_blank');
    }
  }
   
}

</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=News+Cycle:wght@400;700&display=swap');

.contain{
  width: 100%;
}

.nameL{
  text-align: center;
  
}

.text-h4{

background: linear-gradient(to left, #393c8b 68%, #ee5e3b 22%);
filter: drop-shadow(2px 1px 2px white);
-webkit-background-clip: text;
background-clip:text ;

 
}




.contain-title{
  background-color: yellow;

  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  
  h2{ 
  font-family: "DM Sans", sans-serif;

  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-size: 38px;
  }


  
  


}



.span2::after{
      content: " ";
    
    width: 3px;
    height: 90%;
    display: block;
   
    margin: 0px 150px 0px 0px;
   background-color: red;
    }



.btns{
  box-shadow: .2px .2px .2px  black;
  border-radius: 50px;
 


  h4{
    color: white;
  }
}



.contain-data{
  align-content: center;
  
  min-height: 100%;
}

.demo{
  color: white;
  margin: 2%;
  justify-content: end;
  
}


  
</style>