<template>

  <v-container    class="section-5">
  
  <observable-element @intersect="onIntersect">
      <v-row class="title-gallery">
          <h2 class="h1-title" :class="{ 'fade-in': isVisible, 'initial-hidden': !isVisible}">PORTAFOLIO GALERÍA</h2>
      </v-row>
  </observable-element>
  
  <observable-element @intersect="onSubIntersect">
      <v-row class="h4-title ">
          <h4 :class="{ 'fade-sub': isSubVisible, 'initial-hidden': !isSubVisible}" >Contamos con la siguiente Galería de Fotos de cada servicio que ofrecemos</h4>
      </v-row>
  </observable-element>
  
  <!----><observable-element @intersect="onBtnIntersect">
      <v-row 
      justify="center"
      align="center" 
      class="btns-row"
      >
     
          <v-row align="center" justify="center" no-gutters>
            <v-col v-for="(button,index) in buttons" :key="index" cols="auto">
              <v-btn  class="btnss"
              :class="{ 'fade-btn': isBtnVisible, 'initial-hidden': !isBtnVisible}" 
              @click="filterGallery(button.category)" 
              :style="{ transitionDelay: `${index * 200}ms` }" >
                <span class="button-text">
                  {{ button.text }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
   
  
          </v-row>
  <!--<v-col cols="12" lg="12" v-for="(button, index) in buttons" :key="index">
          <v-btn 
          :class="{ 'fade-btn': isBtnVisible, 'initial-hidden': !isBtnVisible}" 
          @click="filterGallery(button.category)" 
          :style="{ transitionDelay: `${index * 200}ms`, width: getButtonWidth(button.text) }"
          
          > <span class="button-text">{{ button.text }}</span></v-btn>
        </v-col>-->
  </observable-element>
  
  <observable-element @intersect="onGaleryIntersect">
  <v-row  class="port-gallery" >
        <!--<v-col  v-for="(image,index) in filteredImages" :key="image.src" cols="12" sm="8" md="4" lg="4" xl="2">-->
          <v-col  :class="{ 'fade-galery': isGalleryVisible, 'initial-hidden': !isGalleryVisible}"  v-for="(image,index) in filteredImages" :key="image.id" cols="12" sm="7" md="4" lg="4" xl="2">
    
          <v-card class="img-box-1"  height="250"  :class="{ 'fade-galery': isGalleryVisible && index <= visibleImageIndex }"
          :style="{ transitionDelay: `${index * 1000}ms`,'initial-hidden': !isGalleryVisible }" >
            <v-img  :src="image.src" :alt="'Image' +  image.id" height="100%" cover>
              <template v-slot:placeholder> 
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-overlay absolute :value="true" class="overlay d-flex align-center justify-center">
              <span class="text-center white--text">
                <h3>{{ image.description }}</h3>
                <a v-html="image.credit" style="display: none;"></a>
              </span>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </observable-element>
  </v-container>  
  
  
  </template>
  
  <script>
  import ObservableElement from '@/components/ObservableElement.vue'
  
  export default{
      name: 'portafolioGaleria',
      components: {
      ObservableElement
    },
      data(){
          return{
  
  
  buttons: [
          {index:1, text: 'Obra Civil', category: 'category1' },
          {index:2, text: 'Estructuras, Paileria y Maquinados', category: 'category2' },
          {index:3, text: 'Pisos Epóxicos y Acabados', category: 'category3' },
          {index:4, text: 'Instalaciones y Movimiento de Maquinas', category: 'category4' },
          {index:5, text: 'Diseño 3D, Render, Lay Out', category: 'category5' },
          {index:6, text:'Y más...', category: 'plus'}
          ],
  
              images:[
                  {id:1,src:require('../assets/portafo/albañil.jpg'), category: 'category1', description: 'Personal con Habilidades de Albañilería', credit: '<a href="https://pxhere.com/es/photo/750772?utm_content=clipUser&utm_medium=referral&utm_source=pxhere">PxHere</a>', alt:'albañiles'}, 
                  {id:2,src:require('../assets/portafo/maquinaria.jpg'), category: 'category1', description: 'Maquinaria Especial para Escavación', credit:'<a href="https://pxhere.com/es/photographer/783631?utm_content=clipUser&utm_medium=referral&utm_source=pxhere">architectphd</a>', alt:'escavación'},
                  {id:3,src:require('../assets/portafo/ciment.jpg'), category: 'category1', description: 'Equipos y Productos para Cimentación', credit:'<a href="https://pxhere.com/es/photo/743008?utm_content=clipUser&utm_medium=referral&utm_source=pxhere">PxHere</a>', alt:'cimentación'},
                  
  
                  {id:4,src:require('../assets/portafo/operadormaquina.jpg'), category: 'category2', description: 'Personal Capacitado para Maquinas CNC', credit: '<a href="https://www.freepik.es/foto-gratis/ingeniero-serio-vidrios-que-opera-maquina_8132481.htm#fromView=search&page=1&position=3&uuid=b55752f0-a781-48ba-b285-08e76781dd9d">Imagen de pch.vector en Freepik</a>', alt:'maquinacnc'},
                  {id:5,src:require('../assets/portafo/estructura.jpg'), category: 'category2', description: 'Montaje de Estructuras de Acero',credit:'<a href="https://pxhere.com/es/photo/554210?utm_content=clipUser&utm_medium=referral&utm_source=pxhere">PxHere</a>', alt:'acero'},
                  {id:6,src:require('../assets/portafo/paileria.jpg'), category: 'category2', description: 'Realizacion de Pailería en General', credit:'<a href="https://www.freepik.es/foto-gratis/hombre-uniforme-mascara-protectora-esta-trabajando-fabrica-metal_25130150.htm#fromView=search&page=1&position=15&uuid=4c2b8588-57f3-4c5c-96c4-49e48ce033fa">Imagen de fxquadro en Freepik</a>', alt:'pailería'},
  
  
                  {id:7,src:require('../assets/portafo/eposo.jpg'), category: 'category3', description: 'Acabado de Piso con Maquina de Abrasión Diamante', credit:'<a href="https://www.freepik.es/foto-gratis/espacios-vacios-aparcamiento_889033.htm#fromView=search&page=1&position=1&uuid=af14979b-34fa-43ff-871c-8d22bb60b9f2">Imagen de evening_tao en Freepik</a>', alt:'piso'},
                  {id:8,src:require('../assets/portafo/pisoeu.jpg'), category: 'category3', description: 'Acabado de Piso con Material Poliuretano Resistente', alt:'poliuretano'},
                  {id:9,src:require('../assets/portafo/pisoepo.jpg'), category: 'category3', description: 'Acabado de Piso con Material a Base de Resina Epoxi', credit:'<a href="https://www.freepik.com/free-photo/men-working-with-heavy-objects_14477264.htm#fromView=search&page=1&position=33&uuid=b9bbb6c3-50b3-420a-9d3d-ff99d01fce34">Image by freepik</a>', alt:'resina'},
                  
                  
                  {id:10,src:require('../assets/portafo/instalacion.jpg'), category: 'category4', description: 'Instalaciones Eléctricas y Neumaticas',credit:'<a href="https://www.freepik.com/free-photo/modern-operational-plant-equipment-assembly-line-producing-fiberglass-heavy-industry-machinery-metalworking-workshop-concept_26150822.htm#fromView=image_search&page=1&position=0&uuid=08054a83-6635-4a4a-9254-121b0d8395a0">Image by usertrmk on Freepik</a>', alt:'electrico'},
                  {id:11,src:require('../assets/portafo/tierra.jpg'), category: 'category4', description: 'Instalaciones Hidraulica',credit:'<a href="https://www.freepik.com/free-photo/closeup-view-cylindrical-grinder-industrial-concept_10606095.htm#fromView=image_search&page=1&position=21&uuid=3af61f08-4497-4e12-aa5b-8c9b91c328c2">Image by wirestock on Freepik</a>', alt:'hidraulica'},
                  {id:12,src:require('../assets/portafo/brazos.jpeg'), category: 'category4', description: 'Movimiento de Maquinaria', alt:'maquinaria'},
              
                  {id:13,src:require('../assets/portafo/3design.jpg'), category: 'category5', description: 'Diseño y Renderizacion 3D de Interiores ', alt:'diseño3d'},
                  {id:14,src:require('../assets/portafo/arquitec.jpg'), category: 'category5', description: 'Diseño y Renderizacion 3D de Hogares ', alt:'render'},
                  {id:15,src:require('../assets/portafo/render.jpg'), category: 'category5', description: 'Diseño y Renderizacion 3D de Estructuras Industriales', alt:'estructura_industrial'},
              
                  {id:16,src:require('../assets/portafo/oficina.jpg'), category: 'plus', description: 'Ampliación en Todo Tipo de Oficinas', alt:'oficina'},
                  {id:17,src:require('../assets/portafo/naves.jpeg'), category: 'plus', description: 'Ampliacion en Todo Tipo de Naves Industriales', alt:'nave'},
                  {id:18,src:require('../assets/portafo/remod.jpeg'), category: 'plus', description: 'Remodelaciones en General', alt:'remodelacion'},
              
                ],
              selectedCategory:'category1',
              selectedCategory2:'category2',
              selectedCategory3:'category3',
              selectedCategory4:'category4' ,
              selectedCategory5:'category5',
              selectedCategoryplus: 'plus',
  
              isVisible: false,
              isSubVisible: false,
              isBtnVisible: false,
              isGalleryVisible: false,
              
              responsiveButtonWidth: null
          }
      },
  
      mounted() {
    this.setResponsiveButtonWidth();
    window.addEventListener('resize', this.setResponsiveButtonWidth);
  },
  
  
      computed:{
  
        getButtonWidth() {
      return (buttonText) => {
        const charLength = buttonText.split(' ').length;
        if (this.$vuetify.breakpoint.xs) {
          return `${Math.min(charLength * 56, 380)}px`;
        } else if (this.$vuetify.breakpoint.sm) {
          return `${Math.min(charLength * 80, 480)}px`;
        }else if (this.$vuetify.breakpoint.lg) {
          return `${Math.min(charLength * 65, 1280)}px`;
        }
        else {
          return 'auto';
        }
      }
    },
          filteredImages(){
            //return this.filteredImages = this.images.filter(img => img.category === category);
              return this.images.filter(image=>image.category === this.selectedCategory);
          }
      },
  
      methods:{
  
  
          onIntersect(){
            this.isVisible = true
          },
          onSubIntersect(){
          
              this.isSubVisible = true
  
          },
          onBtnIntersect(){
           setTimeout(() => {
            this.isBtnVisible = true 
           }, 100);
           
            
          },
  
          onGaleryIntersect(){
            this.isGalleryVisible = true
            this.startImageAnimation();
          },
          startImageAnimation(){
            this.visibleImageIndex = -1;
        clearInterval(this.animationInterval);
        this.animationInterval = setInterval(() => {
          if (this.visibleImageIndex < this.filteredImages.length - 1) {
            this.visibleImageIndex++;
          } else {
            clearInterval(this.animationInterval);
          }
        }, 1000);
          },
          
  
          filterGallery(category){
              this.selectedCategory = category;
              this.$nextTick(() => {
          this.startImageAnimation();
            });
          },
        },
          beforeDestroy() {
            clearInterval(this.animationInterval);
       
      }
     
  }
  
  
  </script>
  
  <style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
  @import url('https://unpkg.com/boxicons@2.1.1/css/boxicons.min.css');
  
  .section-5{
      margin: 10% auto;
      
  }
  
  .title-gallery{
   justify-content: center;
   text-align:center;
   margin: 2% auto;
  
      .h1-title{
                  justify-content: center;
                  margin: 5% auto;
                  font-family: "Questrial", sans-serif;
                  font-weight: 450;
                  font-style: normal;
                  font-size: 58px;
                  margin-bottom: 10px;
                  color: #5C64C2; 
              }
  
              h2::after {
      content: " ";
      width: 30%;
      height: 4px;
      display: block;
      
      margin: 0 auto;
      background-color: #302e87;
    }
    
            
  }
  
  
  .h4-title{
  text-align: center;
  justify-content: center;
  margin: 5% auto;
  font-family: "Raleway",         sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-size: 20px;
        }
  
  .btnss{
    display: flex;
    flex-wrap: wrap;
    white-space: wrap ;
    overflow: hidden;
    margin: 3%;
    text-overflow: ellipsis;
    width: auto;
    height: auto;
  }
  
  
  .button-text {
    min-width: fit-content;
    display: flex;
    flex: 1;
   color: black;
    
         }
  
  
  .port-gallery {
    margin: 5% auto;
      width: 100%;
    .img-box-1 {
      position: relative;
      overflow: hidden;
      width: 100%;
      border-radius: 15px;
      
  
      .overlay {
        opacity: 0;
        transition: opacity 0.1s ease;
      }
  
      &:hover .overlay {
        opacity: 1;
        background-color: rgba(0, 68, 128, 0.623);
        cursor: pointer;
      }
    }
  }
  
  .initial-hidden {
    opacity: 0;
    transform: translateY(0);
  }
  .fade-in{
    opacity: 1;
    transform: translateY(-15px);
    transition: opacity .5s ease-out, transform 0.5s ease-out  ; 
  }
  
  .fade-sub{
    opacity: 1;
    transform: translateY(-15px);
    transition: opacity 1.5s ease-out, transform 1.5s ease-out  ; 
  
  }
  .fade-btn, .fade-galery {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
  
  
  
  </style>