<!-- src/components/ObservableElement.vue -->
<template>
    <div ref="observableElement">
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    name: 'observableElement',
    props: {
        threshold: {
        type: Number,
        default: 0.8
      },
      rootMargin: {
        type: String,
        default: '0px'
      }
    },
    data() {
      return {
        isIntersecting: false
      }
    },
    mounted() {
      const observer = new IntersectionObserver(
        ([entry]) => {
          this.isIntersecting = entry.isIntersecting
          if (this.isIntersecting) {
            this.$emit('intersect')
          }
        },
        {
          threshold: this.threshold,
          rootMargin: this.rootMargin
        }
      )
      observer.observe(this.$refs.observableElement)
    }
  }
  </script>