<template >

<v-app  >
    <v-main class="section-6">
      <observable-element @intersect="onIntersect">
         <v-row :class="{ 'fade-in': isVisible, 'initial-hidden': !isVisible}" class="section-title" >
          <h2>NUESTROS CLIENTES</h2>
         </v-row>
      </observable-element>

      <observable-element @intersect="onSubIntersect">
         <v-row class="section-descrip" cols="12" lg="4">
            <h4 :class="{ 'fade-sub': isSubVisible, 'initial-hidden': !isSubVisible}">En SERMA, entendemos que el éxito de nuestros clientes depende en gran medida de la capacidad para enfrentar desafíos complejos con soluciones innovadoras y eficientes. Es por eso que estamos dedicados a proporcionar servicios integrados que abarcan el diseño, ejecución y desarrollo de proyectos, asegurando que cada fase del proceso sea manejada con la máxima atención al detalle y profesionalismo.</h4>
          </v-row>
      </observable-element>

         <v-carousel
                :show-arrows="false"
                hide-delimiter-background
                delimiter-icon
                height="150"
                :continuous="true"
                :cycle="true"
                interval="2500"
                class="custom-carousel"
          >
                <v-carousel-item
                    v-for="(logo, i) in clientLogos"
                    :src="logo"
                    :key="i"
                    
                    contain
                >
                
                </v-carousel-item>
          </v-carousel>
</v-main>
</v-app>



<!--
    <div class="section-6">
    
        <div class="section-container">
            <div class="section-title">
                <h2>Nuestros Clientes</h2>
                <hr/>
            </div>

            <div class="section-descrip">
                <h4>En SERMA, entendemos que el éxito de nuestros clientes depende en gran medida de la capacidad para enfrentar desafíos complejos con soluciones innovadoras y eficientes. Es por eso que estamos dedicados a proporcionar servicios integrados que abarcan el diseño, ejecución y desarrollo de proyectos, asegurando que cada fase del proceso sea manejada con la máxima atención al detalle y profesionalismo.
                </h4>
            </div>

            <div class="slider" 
            style="
                --heigh:100px;
                --width:140px;
                --quantity:5;
                ">
                <div class="list" >
                    <div class="item" style="--position:1"> <img  src="../../public/client-logo.png"/></div>
                    <div class="item" style="--position:2"> <img src="../../public/client-logo2.png"/></div>
                    <div class="item" style="--position:3"> <img src="../../public/client-logo3.png"/></div>
                    <div class="item" style="--position:4"> <img src="../../public/client-logo.png"/></div>
                    <div class="item" style="--position:5"> <img src="../../public/client-logo.png"/></div>
                </div>

            </div>

        </div>

    </div>-->

</template>

<script>
import ObservableElement from '@/components/ObservableElement.vue'
export default {
  name: 'ClientsView',
  components: {
    ObservableElement
  },
  data() {
    return {
      clientLogos: [
        require('../../public/client-logo.png'),
        require('../../public/client-logo2.png'),
        require('../../public/client-logo3.png'),
        require('../../public/client-logo4.png'),
        require('../../public/client-logo5.png'),
        require('../../public/client-logo6.png'),
        require('../../public/client-logo7.png'),
        require('../../public/client-logo8.png'),
        require('../../public/client-logo9.png')
      ],
      isVisible: false,
      isSubVisible: false,
    }
  },

  methods:{
    onIntersect(){
      this.isVisible = true
    },

    onSubIntersect(){
      this.isSubVisible = true
    }
  }


}

</script>

<style>

.section-6{
    background-color: #E1E0E0;
    padding:10% 0% 10% 0%;
    margin-top: 5%;
}

.section-title{
        text-align:center;
        justify-content: center;
        margin: auto;
 

            h2{
                font-family: "Questrial", sans-serif;
                font-weight: 450;
                font-style: normal;
                font-size: 58px;
                margin-bottom: 30px;
                color: #5C64C2;   
               }

               h2::after {
                content: " ";
                width: 30%;
                height: 4px;
                display: block;
                margin: 0 auto;
                background-color: #302e87;
                }

}

.section-descrip{
                text-align:center;
                justify-content: center;
                margin: 5% 5%;

                    h4{
                    font-family: "Raleway", sans-serif;
                    font-optical-sizing: auto;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 20px;
                    }
}

.v-carousel {
  box-shadow: none;
  background-color: transparent;
  width: auto;
  margin-bottom: 5%;
 
    height:var(--heigh);
    overflow: hidden;
    mask-image: linear-gradient(
        to right,
        transparent,
        #000 10% 90%,
        transparent
    );
}


.custom-carousel >>> .v-carousel__controls {
  display: none;
}



</style>