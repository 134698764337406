<template>
  <v-container
  id="nosotros" 
   class="section-2">
    <observable-element @intersect="onIntersect">
      <v-row class="about-us" >
        <h2 :class="{ 'fade-in': isVisible, 'initial-hidden': !isVisible}">QUIENES SOMOS</h2>
      </v-row>
    </observable-element>

    <observable-element @intersect="onIntersectImg">
      <v-row class="about-section" justify="center">
        <v-col class="about-img" cols="10" xs="4" md="5" lg="5">
          <v-img src="../../public/imgAbot.png" :class="{ 'fade-right': isImgVisible, 'initial-hidden': !isImgVisible  }" alt="sermagroup"></v-img>
        </v-col>
   
        <v-col class="about-content"  cols="12" xs="5" sm="5" md="5" lg="5">
          <span :class="{ 'fade-right': isImgVisible, 'initial-hidden': !isImgVisible }">
            " SERMA es una empresa enfocada en encontrar soluciones integrales a los requerimientos de nuestros clientes, ofreciendo servicios integrados en el diseño, ejecución y desarrollo de proyectos "
          </span>
        </v-col>

      </v-row>
    </observable-element>

  
  </v-container>
</template>

<script>
import ObservableElement from '@/components/ObservableElement.vue'
//import Intersect from "vue-intersect"
export default {
  name: 'AboutView',
  components: {
    ObservableElement
  },
  data() {
    return {
      isVisible: false,
      isImgVisible: false,
   
    }
  },
  methods: {
    onIntersect() {
      this.isVisible = true
    },
    onIntersectImg() {
      this.isImgVisible = true
    },
  }
}
</script>

<style lang="scss" scoped>
 @import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,100..900;1,100..900&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=News+Cycle:wght@400;700&display=swap'); 
 @import url('https://fonts.googleapis.com/css2?family=Explora&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Questrial&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

  //@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wdth,wght@0,6..12,111.3,612;1,6..12,111.3,612&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  .section-2{
    margin-bottom: 5%;
  }

  .about-us{
   
    justify-content: center;
    padding: 5% 0% 0% 0%;

  h2 {
    font-family: "Questrial", sans-serif;
    font-weight: 450;
    font-style: normal;
    font-size: 58px;
    text-align: center;
    color: #5C64C2; 
    margin-bottom: 25px;

  }

  h2::after {
    content: " ";
    width: 30%;
    height: 4px;
    display: block;
    margin: 0 auto;
    background-color: #302e87;
    border-radius: 45px;
  }
  }



.about-img {
  align-items: center;
 
  img {
    align-items: center;
    border-radius: 23px ;
    max-width: 100%;
    display: block;
    transition: 0.5s ease;
  }
}

.about-content {
 
  text-align: center;
  align-content: center;
  span {
    font-family: "Chivo", sans-serif, serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 25px;
    line-height: 1.5;
    color:black;
  }
}


.fade-in {
  opacity: 1;
  transform: translateY(0);
  transition: opacity .5s ease-out, transform 0.5s ease-out  ;
}

.fade-right{
  opacity: 1;
  transform:  translateX(0px);
  transition: opacity .5s ease-out, transform 0.5s ease-out;
}

.initial-hidden {
  opacity: 0;
  transform: scale(1) translateY(0);
}







</style>
  <!--
  <style lang="scss" >
  @import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Explora&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Questrial&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wdth,wght@0,6..12,111.3,612;1,6..12,111.3,612&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  .section-2{
    margin-top: 5%;
  }

  .about-us{
   
    justify-content: center;
    padding: 5% 0% 0% 0%;

  h1 {
    font-family: "Questrial", sans-serif;
    font-weight: 450;
    font-style: normal;
    font-size: 60px;
    text-align: center;
    color: #5C64C2; 
    margin-bottom: 25px;

  }

  h1::after {
    content: " ";
    width: 100%;
    height: 4px;
    display: block;
    margin: 0 auto;
    background-color: cyan;
  }
  }



.about-img {
  align-items: center;
 
  img {
    align-items: center;
    border-radius: 13px ;
    max-width: 100%;
    display: block;
    transition: 0.5s ease;
  }
}

.about-content {
 
  text-align: center;
  align-content: center;
  span {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 25px;
    line-height: 1.5;
    color:black;
  }
}

@media screen and (max-width: 769px) {
  .section-about {
    flex-direction: column; /* Cambia a columna en pantallas pequeñas */
    align-items: center;
  }

  .about-img {
    margin-right: 0;
    margin-bottom: 20px; /* Añade un margen inferior para separar la imagen del texto */
  }

  .about-content p {
    font-size: 17px;
    margin-bottom: 0;
    padding: 0;
  }

  .cont-all {
    padding: 0;
  }
}
  
  </style>
-->