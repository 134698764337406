<template >
<div id="section-4" >

<observable-element @intersect="onIntersect">
    <v-row class="title-container">
        <h2 :class="{'fade-in': isVisible, 'initial-hidden': !isVisible}">NUESTROS SERVICIOS</h2>   
    </v-row>
</observable-element>

<observable-element @intersect="onSubIntersect">
    <v-row class="title2-container">
        <h4 :class="{'fade-midd': isSubVisible, 'initial-hidden': !isSubVisible}">Contamos con los siguientes servicios para cada tipo de implementacion de proyectos e instalaciones</h4>
    </v-row>
</observable-element>


<observable-element @intersect="onIntersectCard">
    
<v-row justify="center" align="center" no-gutters>
<v-col v-for="(card, index) in cards"  :key="index"   cols="12" xs="5" sm="6" lg="4 ">
    <v-card class="card mx-auto"
          height="290px"
          max-width="400"
          :class="{ 'fade-catch': isCardVisible, 'fade-catall': !isCardVisible }"
         >

        <v-img :src="card.image" height="200px" ></v-img>
        <v-card-title :class="card.titleClass" height="300px">{{ card.title }}</v-card-title>
        <a v-html="card.credit" style="display: none;"></a> 
    </v-card>
</v-col>

</v-row>



</observable-element>
        
   
</div>



</template>
<script>
import ObservableElement from '@/components/ObservableElement.vue'
export default {
  name: "ServicesView",
  components: {
    ObservableElement
  },
  data() {
    return {
        isVisible: false,
        isSubVisible: false,
        isCardVisible: false,


        cards:[{ 
          title: "OBRA CIVÍL", 
          image: require("@/assets/servic/obra.jpg"),
          delay: 0,
         credit: '<a href="https://www.freepik.es/foto-gratis/trabajadores-sitio-construccion_11343732.htm#fromView=image_search&page=1&position=0&uuid=0111d665-7b18-4f62-9ce2-7c060eb190af">Imagen de wirestock en Freepik</a> ',
         alt: 'obraalbañil'
        },
        { 
          title: "ESTRUCTURA METÁLICA, PAILERIA Y MAQUINADOS", 
          image: require("@/assets/servic/metalica.jpg"),
          delay: 200,
          credit:'<a href="https://www.freepik.com/free-photo/safety-extinguisher-instrument-utility-faucet_1046061.htm#fromView=image_search_similar&page=1&position=41&uuid=e575dde7-93aa-4107-97b9-13c5cfd678e2">Image by evening_tao on Freepik</a>',
          alt: 'metal'

        },
        { 
          title: "INSTALACIONES ELÉCTRICAS Y MOVIMIENTO DE MAQUINAS", 
        
          image: require("@/assets/servic/electri.jpg"),
          delay: 400,
          credit:'<a href="https://www.freepik.com/free-photo/male-electrician-with-safety-glasses-helmet-near-switchboard_32846464.htm#fromView=search&page=1&position=42&uuid=09558444-2f04-44fd-9a20-17139daa18f3">Image by pvproductions on Freepik</a>',
          alt: 'instalacion'
        },
    ]
    }
},

methods:{
    onIntersect(){
        this.isVisible = true
    },

    onSubIntersect(){
        this.isSubVisible = true
    },

    onIntersectCard(){
        this.isCardVisible = true
    },

    

}
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Explora&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Questrial&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

#section-4{
    background-color: #E1E0E0;
    margin-top: 5%;
    padding: 10% 0% 5% 0%;
}


.title-container{
 
  justify-content: center;
  text-align:center;
  margin: 2% auto;
 

  h2{
        font-family: "Questrial", sans-serif;
        font-weight: 450;
        font-style: normal;
        font-size: 58px;
        margin-bottom: 10px;
        color: #5C64C2;   
}

h2::after {
    content: " ";
    width: 30%;
    height: 4px;
    display: block;
    margin: 0 auto;
    background-color: #302e87;
  }
}






.title2-container{
  justify-content: center;

  text-align:center;
  margin: 5% auto;

    h4{
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    
    
    }
}


.card{
 
margin-bottom:5%;
  //transition:  transform 300ms ease-in;
    
.v-card__title{
  
  height: 35%;
  //background-color: yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;

 
 }
 .custom-title {
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
 
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 20px; // Tamaño de fuente más grande para el primer título
  line-height: 1.2; // Ajusta el interlineado si es necesario
}

}




.fade-in {

  opacity: 1;
  transform: translateY(-25px);
  transition: opacity .5s ease-out, transform 0.5s ease-out  ;
}

.fade-midd{
  opacity: 1;
  transform: translateY(-40px);
  transition: opacity 1s ease-out, transform 1s ease-out  ; 
}

.fade-catch {
  animation: fadeCatch 0.8s ease-out forwards;
}

.fade-catall {

  animation: fadeCat 0.5s ease-in forwards;
}

@keyframes fadeCatch {
  //from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeCat {
  from { opacity: 0; transform: translateY(0); }
  //to { opacity: 1; transform: translateY(20px); }
}

.initial-hidden {
  opacity: 0;
  transform: translateY(0);
  transition: transform 500ms ease-in-out ;
}

/*.card:hover{
    transform: translateY(-28px);
    transition: transform 500ms ease-in-out;
}*/
/*
.fade-cards{
    opacity: 1;
    transform: translateY(-50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out  ; 

}



*/

</style>