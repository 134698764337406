<template>
    
      <!--:class="{'nav-grad':scrolledNav, 'base-color':!scrolledNav}"-->
      <!--:color="scrolledNav ? '#293668' : '#292a68'" height="auto"-->
      <div>
      <v-app-bar app :elevation="scrolledNav ? 4 : 0"
       :class="['nav-grad', { 'mobile-white': mobile }]"
       height="auto">
       <v-app-bar-nav-icon @click="toggleMobileNav" v-if="mobile"></v-app-bar-nav-icon>
          
       <v-row no-gutters align="center"> 
            <v-row class="my-1 mx-2">
              <!--<v-col  cols="2" xs="auto" sm="auto" md="auto" lg="1">
                
              </v-col>
-->              


              <v-col cols="4" xs="auto" lg="1">
                <v-img :src="SermaLogo" max-height="140" contain class="custom-image my-2"></v-img>
              </v-col>
              <v-col cols="8" xs="auto" lg="auto">
                    <!--<h2 class="custom-Logo text-center " style="text-transform: uppercase;">serma group</h2>-->
                    <v-img :src="titleLogo" contain class="custom-Logo"></v-img>
              </v-col>
            
  
            <v-spacer></v-spacer>
 
            <v-col cols="2"  xs="auto" sm="auto" md="auto" lg="auto" v-if="!mobile">
             <v-tabs v-model="activeTab" class="custom-tabs theme--transparent">
              <!--<v-tab v-for="(item, index) in menuItems" :key="index" :to="item.route">--> 
           <v-tab v-for="(item, index) in menuItems" :key="index"  @click="scrollToSection(item.route)">
                {{ item.title }}
              </v-tab>
              <div class="custom-indicator" ></div>
            
             <!--<v-tabs v-model="localActiveTab" class="custom-tabs theme--transparent">
              <v-tab v-for="(item, index) in menuItems" :key="index" :to="item.route">
                {{ item.title }}
              </v-tab>
              <div class="custom-indicator" :style="indicatorStyle"></div>
             </v-tabs>-->
             
              <!--<v-tabs  class="custom-tabs theme--transparent"  >
                <v-tab to="/">Inicio</v-tab>
                <v-tab  to="/about">Nosotros</v-tab>
                <v-tab  to="/service">Servicios</v-tab>
                <v-tab to="/portfolio">Galería</v-tab>
                <v-tab to="/customer">Clientes</v-tab>
                <v-tab to="/contact">Contacto</v-tab>
              -->
              </v-tabs>
            </v-col>
          </v-row>

          
          </v-row>

       
      </v-app-bar>
  

      <v-navigation-drawer v-model="mobileNav" app temporary>
        <v-list>
          <!--<v-list-item v-for="(item, i) in menuItems" :key="i" :to="item.to">-->
          <v-list-item v-for="(item, i) in menuItems" :key="i"  @click="scrollToSection(item.route)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>        
    </div>
</template>

<script>
export default{
    name: "NavBar",
    /*props: ['activeTab'],*/
    data() {
    return {
    scrolledNav: null,
    btnNav: null,
      mobile:null,
      mobileNav:null,
      windowWidth:null,
      activeTab: 0,
      /*activeTab: 0,*/
      SermaLogo: require('@/assets/SermaLogo.png'),
      titleLogo: require('@/assets/title.png'),

      /*watch:{
        activeTab(newVal) {
      this.localActiveTab = newVal;
    },
    localActiveTab(newVal) {
      this.$emit('update:activeTab', newVal);
    }
      }*/
      menuItems:[
      { title: 'Inicio', route: '#homeAll' },
      { title: 'Nosotros', route: '#about' },
      { title: 'Servicios', route: '#services' },
      { title: 'Galería', route: '#galeria' },
      { title: 'Clientes', route: '#client' },

        
      ],
      sections:[
      { id: 'inicio', top: 0 },
      { id: 'nosotros', top: 0 },
      { id: 'servicios', top: 0 },
      { id: 'galeria', top: 0 },
      { id: 'clientes', top: 0 },
    

      ]
    };
  },

created(){
window.addEventListener('resize', this.checkScreen());
this.checkScreen();
},
beforeDestroy() {
  window.removeEventListener('resize', this.checkScreen);
},

computed: {
 
},

mounted(){

    window.addEventListener('resize', this.checkScreen);
},

  methods:{
    toggleMobileNav(){
        this.mobileNav = !this.mobileNav
    },

    checkScreen(){
        this.windowWidth = window.innerWidth;
      this.mobile = this.windowWidth <= 750;
      console.log('Mobile:', this.mobile);

        /*if(this.windowWidth <= 750){
            this.mobile = true;
            return;
        }*/
        /*this.mobile = false;
        this.mobileNav = false;
        return;*/

    },

    scrollToSection(route) {
    const element = document.querySelector(route);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    this.mobileNav = false; // Cierra el menú móvil después de hacer clic
  },
 
 
  }, 
};

</script>


<style lang="scss" scoped>

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



.nav-grad{
  background: linear-gradient(to right, rgb(255, 255, 255) 20% ,rgba(41, 42, 104, 0.992) 50%) ;//background-color: linear-gradient(to right, rgba(255, 255, 255, 0.849) 20% ,rgba(41, 42, 104, 0.992) 50%) !important; /* Color por defecto */
  
  
  //transition: background 1.5s ease;

}

.mobile-white {
  background: white !important;
}

@media (max-width < 750px) {
  .nav-grad {
    background-color: white !important;
  }
}


.custom-Logo{
  filter: drop-shadow(.5px 0px .5px white);
  height: auto;
  width: 250px;
  margin-top:10px;

}



.custom-tabs {
    

    background-color: transparent !important;
    margin-top: 10px;

    .v-tab {
    
    background-color: rgba(41, 42, 104, 0.992) ; // Color de fondo por defecto
    color: white !important; // Color del texto
    opacity: 1 ; // Asegura que el texto sea completamente visible
    font-family: "Poppins", sans-serif;
    font-size: 2.5vh;
    font-weight: 400;
    font-style: normal;


    &::before {
      
      opacity:0; // Elimina el fondo por defecto de Vuetify
    }

    &:hover {
     
      background-color: lighten(rgb(52, 52, 108), 10%) !important; // Color de fondo al pasar el mouse
    } 
  }

  // Elimina la línea inferior de las pestañas si lo deseas
  .v-tabs-slider-wrapper {
    display: none;
  }
}
</style>