<template>
  <v-app id="homeAll">
  
    <NavBar/>
      <!--Dentro de Este Componente tenemos todo lo relacionado con el NavBar-->
  
      <!--Nuestro "Div-Home" es el contenedor de todo el sitio web, donde tenemos todo el main,
       sections, footer, icons, etc. -->
   
  
  
      <section id="home"  class="main" alt="atardecer">
        <v-row class="hero-text-container"  justify="left">
        <v-col cols="12" xs="12" sm="10" md="8" lg="6" xl="4">
          <h1 class="hero-title text-center">
            SOLUCIONES EN DISEÑOS E <span class="break-word">IMPLEMENTACIÓN</span> DE PROYECTOS
          </h1>
        </v-col>
      </v-row>
  
      </section>
  
      <section >
        <div id="about">
          <AboutView/>
        </div>
        <div id="services">
          <ServicesView/>
        </div>
        <div id="galeria"> 
          <GaleriaView/>
        </div> 
        <div id="client"> 
          <ClientsView/>
        </div>
        <FooterView/>
      </section>

  <WspBtn/>
  
  </v-app>
  </template>
  
  
  <!--/////////////////////////////////-->
  <script>
  import NavBar from '@/components/NavBar.vue'
  import AboutView from './AboutView.vue'
  import ServicesView from './ServicesView.vue'
  import GaleriaView from './GaleriaView.vue'
  import ClientsView from './ClientsView.vue'
  import FooterView from '../components/FooterView.vue'
  import WspBtn from '@/components/WspBtn.vue'
  
    export default {
      name: 'MainPage',
      
      components:{
      NavBar,
      AboutView,
      ServicesView,
      GaleriaView,
      ClientsView,
      FooterView,
      WspBtn
      },
  
      mounted(){
        this.$emit('update-active-tab', 0);
      },
  
      methods:{
                  scrollToSection(scrollAmount) {
                  window.scrollBy({
                    top: scrollAmount, // cantidad de píxeles para desplazarse
                    left: 0,
                    behavior: 'smooth'
                  });
                }
              }
    } 
  </script>
  <!--/////////////////////////////////-->
  
  <style lang="scss" scoped>
  
  @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap') ;
  
  .main{
    background-image: url("../assets/MainPa3Or.jpg");
    background-size:cover;
    background-attachment:fixed;
    width: 100%;
    position:relative ;
    height: 110vh;
    
    img {
      object-fit: cover;
      height: 100% ;
      width: 100%;
    }
  
  
  
  
    .hero-text-container{
     
      padding:0% 0% 0% 2.6%;
      align-items: center;
      width: 100%;
      height: 100%;
   
      color: #293085;
  
    }
  
    .hero-title {
    font-size: clamp(2.5rem, 4vw, 3.0rem);
    filter: drop-shadow(1px 1px 1px )black;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 800;
    line-height: 1.2;
    word-wrap: break-word;
    hyphens: auto;
  }
  
  .break-word {
    display: inline-block;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 800;
  }
  
  @media (max-width: 400px) {
    .hero-title {
      font-size: clamp(2.3rem, 5vw, 3.8rem);
    }
  }
  
  
  }
  
  </style>