<template>

<v-app>
  <!--<NavBar />-->
  <!--<navigation-t :active-tab.sync="activeTab" />-->
  <v-main>
  
    <router-view/>
   
  </v-main>
  <!--<WspBtn/>-->

</v-app>
</template>
<script>

//import NavBar from '../src/components/NavBar.vue'
//import AboutView from '../src/views/AboutView.vue'
//import ServicesView from '../src/views/ServicesView.vue'
//import GaleriaView from '../src/views/GaleriaView.vue'
//import ClientsView from '../src/views/ClientsView.vue'
/*import ContactView from '../src/views/ContactView.vue'*/
//import FooterView from '../src/components/FooterView.vue'
//import WspBtn from '../src/components/WspBtn.vue'

export default {
  name: 'App',

  components: {
      //NavBar,
      /*AboutView,*/
      /*ServicesView,*/
      /*GaleriaView,*/
      /*ClientsView,*/
      /*ContactView*/
      /*FooterView,*/
      //WspBtn,
      
    },

    data() {
    return {
      activeTab: 0
    }
  },

  mounted() {
    this.setTitle('SermaGroup - Soluciones en Diseños e Implementación de Proyectos');
  },


  methods: {
    updateActiveTab(index) {
      this.activeTab = index;
    },
    setTitle(newTitle) {
      document.title = newTitle;
    }

  }
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap");
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: 400;
}

.app{
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
}



.container{
  padding: 0 20px;
  max-width: 1140px;
}
</style>